import React from 'react';
import PrivateRoute from '../../components/common/PrivateRoute';
import {Switch, Route} from 'react-router-dom';
import PageError from "../../components/layout/PageError";
import Post from "../../pages/authorized/Post";

const Private = () => {
    return (
        <div>
            <Switch>
                <PrivateRoute exact path="/post" component={Post} />
                <Route component={PageError} />
            </Switch>
        </div>
    )
};

export default Private;
